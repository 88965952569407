<template>
<div class="createapp">
   <div v-if="hasAdminRights">
    <div class="p-d-flex p-grid">
      <div v-if="isLoading">
          <SkeletonAppForm />
      </div>
      <div class="card p-col-12 " v-else id="addApp">
        <div class="breadcrumb-container">
          <b-breadcrumb :items="breadcrumb_items"></b-breadcrumb>
        </div>
        <div class="createapp">
          <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-md-8 p-pt-4 p-pl-4 p-pr-4">
              <span class="p-float-label">
                <InputText id="inputtext" type="text" v-model="appDetails.title" :disabled="issubmit"/>
                <label for="inputtext" class="floatlabel">App Title</label>
              </span>
              <label class="error-message">
                {{ validation.firstError("appDetails.title") }}
                </label>
            </div>
          </div>
          <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-md-4 p-m-0">
              <span class="p-float-label">
                <Dropdown
                  v-model="appDetails.type"
                  :options="appTypes"
                  optionLabel="name"
                  :showClear="true"
                  :filter="true"
                  :disabled="issubmit"
                  filterPlaceholder="Search For Type">
                  <template #option="slotProps">
                    <div>
                      <span
                        ><i :class="slotProps.option.icon" class="p-mr-1"></i></span
                      >{{ slotProps.option.name }}
                    </div>
                  </template>
                </Dropdown>
                <label for="inputtext" class="floatlabel">App Type</label>
              </span>
              <label class="error-message">{{ validation.firstError("appDetails.type") }}</label >
            </div>
            <div class="p-field p-col-12 p-md-4 p-m-0">
              <span class="p-float-label">
                <Dropdown
                 :disabled="issubmit"
                  v-model="appDetails.stage"
                  :options="appStages"
                  optionLabel="name"
                  :showClear="true"
                  :filter="true"
                  filterPlaceholder="Search For Stage"
                >
                  <template #option="slotProps">
                    <div>
                      <span
                        ><i :class="slotProps.option.icon" class="p-mr-1"></i></span
                      >{{ slotProps.option.name }}
                    </div>
                  </template>
                </Dropdown>
                <label for="inputtext" class="floatlabel">App Stage</label>
              </span>
              <label class="error-message">
                {{ validation.firstError("appDetails.stage") }}</label
              >
            </div>
            <div class="p-field p-col-12 p-md-4 p-m-0">
              <span class="p-float-label">
                <AutoComplete
                  :disabled="issubmit"
                  v-model="appDetails.businessOwner"
                  :suggestions="businessOwners"
                  @complete="searchOwnerWithEmail($event)"
                  field="mail"
                >
                  <template #item="slotProps">
                    <div>
                      <div>
                        <span><i class="fas fa-user"></i></span>
                        {{ slotProps.item.displayName }}
                      </div>
                      <div>
                        <span><i class="fas fa-envelope-open-text"></i></span>
                        {{ slotProps.item.mail }}
                      </div>
                    </div>
                  </template>
                </AutoComplete>
                <label for="inputtext" class="floatlabel">App Business Owner</label>
              </span>
              <label class="error-message">
                {{ validation.firstError("appDetails.businessOwner") }}</label
              >
              <small id="username1-help">search with user email</small>
            </div>
          </div>
          <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-md-4 p-m-0">
              <span class="p-float-label">
                <AutoComplete
                  v-model="appDetails.owner1"
                  :disabled="issubmit"
                  :suggestions="appOwners"
                  @complete="searchOwnerWithName($event)"
                  field="mail"
                >
                  <template #item="slotProps">
                    <div>
                      <div>
                        <span><i class="fas fa-user"></i></span>
                        {{ slotProps.item.displayName }}
                      </div>
                      <div>
                        <span><i class="fas fa-envelope-open-text"></i></span>
                        {{ slotProps.item.mail }}
                      </div>
                    </div>
                  </template>
                </AutoComplete>
                <label for="inputtext" class="floatlabel">App Owner 1</label>
              </span>
              <label class="error-message" v-if="validation.firstError('appDetails.owner1')">{{ validation.firstError("appDetails.owner1") }}</label>
               <label class="error-message" v-else-if="isValidAppOwner1">select user from the list </label >
              <small id="username1-help">search with user name</small>
            </div>
            <div class="p-field p-col-12 p-md-4 p-m-0">
              <span class="p-float-label">
                <AutoComplete
                 :disabled="issubmit"
                  v-model="appDetails.owner2"
                  :suggestions="appOwners"
                  @complete="searchOwnerWithName($event)"
                  field="mail"
                >
                  <template #item="slotProps">
                    <div>
                      <div>
                        <span><i class="fas fa-user"></i></span>
                        {{ slotProps.item.displayName }}
                      </div>
                      <div>
                        <span><i class="fas fa-envelope-open-text"></i></span>
                        {{ slotProps.item.mail }}
                      </div>
                    </div>
                  </template>
                </AutoComplete>
                <label for="inputtext" class="floatlabel">App Owner 2</label>
              </span>
              <label class="error-message" v-if="validation.firstError('appDetails.owner2')">{{ validation.firstError("appDetails.owner2") }}</label>
              <label class="error-message" v-else-if="isValidAppOwner2">select user from the list </label >
              <small id="username1-help">search with user name</small>
            </div>
            <div class="p-field p-col-12 p-md-4 p-m-0">
              <span class="p-float-label">
                <AutoComplete
                  :disabled="issubmit"
                  v-model="appDetails.owner3"
                  :suggestions="appOwners"
                  @complete="searchOwnerWithName($event)"
                  field="mail"
                >
                  <template #item="slotProps">
                    <div>
                      <div>
                        <span><i class="fas fa-user"></i></span>
                        {{ slotProps.item.displayName }}
                      </div>
                      <div>
                        <span><i class="fas fa-envelope-open-text"></i></span>
                        {{ slotProps.item.mail }}
                      </div>
                    </div>
                  </template>
                </AutoComplete>
                <label for="inputtext" class="floatlabel">App Owner 3</label>
              </span>
              <label class="error-message" v-if="validation.firstError('appDetails.owner3')">{{ validation.firstError("appDetails.owner3") }}</label>
              <label class="error-message" v-else-if="isValidAppOwner3">select user from the list </label >
              <small id="username1-help">search with user name</small>
            </div>
          </div>
          <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-md-8 p-p-4">
              <span class="p-float-label">
                <MultiSelect
                 :disabled="issubmit"
                  v-model="appDetails.relatedGroups"
                  :options="appGroups"
                  optionLabel="name"
                  display="chip"
                  :filter="true"
                  :showClear="true"
                />
                <label for="inputtext" class="floatlabel"
                  >Related Groups
                  <span class="p-col-1 p-p-0" v-if="appDetails.relatedGroups">
                    <chip
                      class="p-col-chip"
                      v-if="appDetails.relatedGroups.length > 0"
                    >
                      {{ ToArryLength(appDetails.relatedGroups) }}
                    </chip>
                  </span>
                </label>
              </span>
              <label class="error-message">
                {{ validation.firstError("appDetails.relatedGroups") }}</label
              >
            </div>
          </div>
          <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-md-8 p-p-4">
              <span class="p-float-label">
                <Chips separator="," v-model="appDetails.tags" :disabled="issubmit" />
                <label for="inputtext" class="floatlabel"
                  >Tags
                  <span class="p-col-1 p-p-0" v-if="appDetails.tags">
                    <chip class="p-col-chip" v-if="appDetails.tags.length > 0">
                      {{ ToArryLength(appDetails.tags) }}
                    </chip>
                  </span>
                </label>
              </span>
              <small id="username1-help">comma separator for multiple</small>
            </div>
          </div>
          <div class="card">
            <div class="p-d-flex">
              <div class="error-message p-mr-4"  v-if="validation.firstError('appDetails.description')">
                Description Is {{ validation.firstError("appDetails.description") }}
              </div>
              <div class="error-message p-mr-4" v-if="validation.firstError('appDetails.links')">
                Links Are {{ validation.firstError("appDetails.links") }}
              </div>
              <div  class="error-message" v-if="validation.firstError('appDetails.environments')" >
                Environments Are   {{ validation.firstError("appDetails.environments") }}
              </div>
            </div>
            <TabView ref="tabview2" :activeIndex="active">
              <TabPanel header="Description" :disabled="issubmit">
                <div class="p-fluid p-grid">
                  <div class="p-field p-col-12 p-md-9 p-ml-2">
                    <Editor
                      v-model="appDetails.description"
                      editorStyle="height: 177px"
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="Project Links" :disabled="issubmit">
                <AppLinks
                  :addLink="handleAddAppLink"
                  :removeLink="handleRemoveAppLink"
                  :appMasterLinks="appLinks"
                  :appLinks="appDetails.links"
                  :updateLink="handleUpdateAppLink"
                />
              </TabPanel>
              <TabPanel header="Project Environments" :disabled="issubmit">
                <AppEnvironments
                  :addEnvironment="handleAddAppEnvironment"
                  :UpdateEnvironment="handleUpdateAppEnvironment"
                  :removeEnvironment="handleRemoveAppEnvironment"
                  :appEnvironments="appDetails.environments"
                  :appMasterLinks="appLinks"
                  :addEnvironmentLinks="handleAddAppEnvironmentLinks"
                />
              </TabPanel>
              <TabPanel header="Project Versions" :disabled="issubmit">
                <AppVersions
                  :addVersion="handleAddAppVersion"
                  :removeVersion="handleRemoveAppVersion"
                  :appVersions="appDetails.versions"
                  :updateVersion="handleUpdateAppVersion"
                />
              </TabPanel>
              <TabPanel header="Project Discussion" :disabled="issubmit">
                <AppDiscussion
                  :addComment="handleAddAppComment"
                  :removeComment="handleRemoveAppComment"
                  :appComments="appDetails.comments"
                  :updateComment="handleUpdateAppComment"
                />
              </TabPanel>
            </TabView>
          </div>
        </div>
      </div>
      <div class="layout-bottombar p-d-flex p-jc-center">
        <div class="p-py-2">
          <Button class="p-button-secondary"
            @click="CreateApplication()">
            <i class="pi pi-spin pi-spinner" v-if="issubmit" ></i>
            <i class="fas fa-plus" v-else></i><span class="p-ml-2">Submit Application</span> 
          </Button>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <ForbiddenView/>
  </div>
</div>
</template>
<script>
/* eslint-disable no-unused-vars */
import moment from "moment";
import Vue from "vue";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);
import AppLinks from "@/components/Apps/AppLinks";
import AppEnvironments from "@/components/Apps/AppEnvironment";
import AppVersions from "@/components/Apps/AppVersions";
import AppDiscussion from "@/components/Apps/AppDiscussion";
import SkeletonAppForm from "@/components/Shared/SkeletonAppForm.vue";
import ForbiddenView from "../Errors/403.vue"

export default {
  name: "createApp",
  created() {
    this.loadMasetData();
    this.hasAdminRights=localStorage.getItem("isUserAdmin")=='true'?true:false
  },
  components: {
    AppLinks,
    AppEnvironments,
    AppVersions,
    AppDiscussion,
    SkeletonAppForm,
    ForbiddenView
  },
  methods: {
    async loadMasetData() {
      try {
        this.appTypes = await this.$store.dispatch("types/getAppTypes");
        this.appGroups = await this.$store.dispatch("group/getGroups");
        this.appStages = await this.$store.dispatch("stage/getStages");
        this.appLinks = await this.$store.dispatch("link/getLinks");
        this.isLoading = false;
      } catch (error) {
        console.warn(error);
      }
    },
    async getUsersWithEmail(filterquery) {
      try {
        let result = await this.$store.dispatch("user/getUsers", filterquery);
        this.businessOwners = result.data;
      } catch (error) {
        console.warn(error);
      }
    },
    async getUsersWithname(filterquery) {
      try {
        let result = await this.$store.dispatch(
          "user/getUsersWithname",
          filterquery
        );
        this.appOwners = result.data;
      } catch (error) {
        console.warn(error);
      }
    },
    async searchOwnerWithEmail(event) {
      let filterquery = event.query;
      await this.getUsersWithEmail(filterquery);
    },
    async searchOwnerWithName(event) {
      let filterquery = event.query;
      await this.getUsersWithname(filterquery);
    },
    handleAddAppLink(_link) {
      this.appDetails.links = this.appDetails.links || [];
      let link = {
        title: _link.title["name"],
        created_by: this.$store.getters["login/getUser"].name,
        is_active: true,
        url: _link.url,
      };
      this.appDetails.links.push(link);
    },
    handleUpdateAppLink(_link,_index){
    let link = {
        title: _link.title["name"],
        created_by: this.$store.getters["login/getUser"].name,
        is_active: true,
        url: _link.url,
      };
      this.appDetails["links"][_index]=link;
    },
    handleRemoveAppLink(_index) {
      this.appDetails.links.splice(_index, 1);
    },
    handleAddAppComment(_comment) {
      let comment = {
        comment: _comment,
        created_by: this.$store.getters["login/getUser"].name,
        created_at: moment.utc(),
        is_active: true,
      };
      this.appDetails.comments = this.appDetails.comments || [];
      this.appDetails.comments.push(comment);
    },
    handleRemoveAppComment(_index) {
      this.appDetails.comments.splice(_index, 1);
    },
    handleUpdateAppComment(_comment,_index){
    this.appDetails["comments"][_index].comment=_comment;
    },
    handleAddAppEnvironment(_env) {
      let environment = {
        name: _env.name,
        title: _env.title,
        description: _env.description,
        url: _env.url,
        created_by: this.$store.getters["login/getUser"].name,
        is_active: true,
        links: [],
      };
      this.appDetails.environments = this.appDetails.environments || [];
      this.appDetails.environments.push(environment);
    },
    handleUpdateAppEnvironment(_env,_index){
      let environment = {
        name: _env.name,
        title: _env.title,
        description: _env.description,
        url: _env.url,
        created_by: this.$store.getters["login/getUser"].name,
        is_active: true,
        links: _env.links,
      };
    this.appDetails["environments"][_index]=environment;
    },
    handleAddAppEnvironmentLinks(environment) {
      let _environment = environment;
     // let _links = _environment.links;
      // this.appDetails["environments"].forEach((element) => {
      //   if (element.title == _environment.title) {
      //     element.links = element.links || [];
      //     _links.forEach((link) => {
      //       element["links"].push(link);
      //     });
      //   }
      // });
      console.log(_environment);
    },
    handleRemoveAppEnvironment(_index) {
      this.appDetails.environments.splice(_index, 1);
    },
    handleAddAppVersion(_version) {
      let version = {
        title: _version.title,
        start_date: _version.start_date,
        go_live_date: _version.go_live_date,
        description: _version.description,
        created_by: this.$store.getters["login/getUser"].name,
        is_active: true,
      };
      this.appDetails.versions = this.appDetails.versions || [];
      this.appDetails.versions.push(version);
    },
    handleUpdateAppVersion(_version,_index){
      let version = {
        title: _version.title,
        start_date: _version.start_date,
        go_live_date: _version.go_live_date,
        description: _version.description,
        created_by: this.$store.getters["login/getUser"].name,
        is_active: true,
      };
      this.appDetails.versions[_index]=version;
    },
    handleRemoveAppVersion(_index) {
      this.appDetails.versions.splice(_index, 1);
    },
    async CreateApplication() {
      this.submitted = true;
      let isInvalidValidAppOwners= this.isValidAppOwner1 || this.isValidAppOwner2 || this.isValidAppOwner3
      await this.$validate().then((success) => {
        if (success && !isInvalidValidAppOwners) {
          let input = this.appDetails;
          this.issubmit=true;
          let appowner1 = {
            displayName: input.owner1.displayName,
            email: input.owner1.mail,
            id: input.owner1.id,
          };
          let appowner2 = {
            displayName: input.owner2.displayName,
            email: input.owner2.mail,
            id: input.owner2.id,
          };
          let appowner3 = {
            displayName: input.owner3.displayName,
            email: input.owner3.mail,
            id: input.owner3.id,
          };
          let businessOwner = {
            displayName: input.businessOwner.displayName,
            email: input.businessOwner.mail,
            id: input.businessOwner.id,
          };
          let versions= input.versions;
          if (versions.length){
            versions.forEach(element => {
              element.start_date=element.start_date.toLocaleDateString();
              element.go_live_date=element.go_live_date.toLocaleDateString()
            });
          }
          let relatedGroups = input.relatedGroups.map((rgp) => rgp.name);
          let application = {
            title: input.title,
            description: input.description,
            links: input.links,
            app_type: input.type["name"]?input.type["name"]:input.type,
            environments: input.environments,
            related_groups: relatedGroups,
            stage: input.stage["name"]?input.stage["name"]:input.stage,
            business_owner: businessOwner.email,
            business_owner_name: businessOwner.displayName,
            tags: input.tags,
            comments: input.comments,
            versions: versions,
            app_owner1: appowner1,
            app_owner2: appowner2,
            app_owner3: appowner3,
          };
          try {
             this.$store.dispatch("app/createApplication", application); 
            setTimeout(() => {
              this.issubmit=false;
             this.$router.push({ name: "apps" }); 
            }, 3000);
          } catch (error) {
            console.warn(error);
          }
        }
      });
    },
  },
  props: {},
  watch: {
    "appDetails.owner1": function(newVal) {
      let value= newVal.mail?newVal.mail:newVal;
      if(value.indexOf('@areteir') < 0){
        this.isValidAppOwner1=true;
      }
      else{
        this.isValidAppOwner1=false;
      }
    },
    "appDetails.owner2": function(newVal) {
      let value= newVal.mail?newVal.mail:newVal;
      if(value.indexOf('@areteir') < 0){
        this.isValidAppOwner2=true;
      }
      else{
        this.isValidAppOwner2=false;
      }
    },
    "appDetails.owner3": function(newVal) {
      let value= newVal.mail?newVal.mail:newVal;
      if(value.indexOf('@areteir') < 0){
        this.isValidAppOwner3=true;
      }
      else{
        this.isValidAppOwner3=false;
      }
    }
  },
  data() {
    return {
      isValidAppOwner1:false,
      isValidAppOwner2:false,
      isValidAppOwner3:false,
      hasAdminRights:false,
      appGroups: [],
      appStages: [],
      appTypes: [],
      appLinks: [],
      appOwners: [],
      businessOwners: [],
      issubmit:false,
      active: 0,
      isLoading: true,
      isCreating: false,
      appDetails: {
        title: "",
        type: "",
        stage: "",
        businessOwner: "",
        businessOwnerName:"",
        owner1: "",
        owner2: "",
        owner3: "",
        relatedGroups: "",
        tags: "",
        description: "",
        links: [],
        environments: [],
        versions: [],
        comments: [],
      },
      submitted:false,
      breadcrumb_items: [
        {
          text: "Home",
          to: { name: "apps" },
        },
        {
          text: "Create App",
          active: true,
        },
      ],
    };
  },
  validators: {
    "appDetails.title": function (value) {
      return Validator.value(value).required().regex('^[A-Za-z 0-9.]*$', 'Must only contain alphanumeric with(.) characters.');
    },
    "appDetails.type": function (value) {
      return Validator.value(value).required();
    },
    "appDetails.stage": function (value) {
      return Validator.value(value).required();
    },
    "appDetails.businessOwner": (value) => {
      return Validator.value(value.mail?value.mail:value).required().email();
    },
    "appDetails.owner1": function (value) {
      return  value.mail? Validator.value(value.mail).required().email():Validator.value(value).required().regex('^[A-Za-z ]*$', 'Must only contain alphabetic characters.')
    },
    "appDetails.owner2": function (value) {
      return  value.mail? Validator.value(value.mail).required().email().custom(() =>
      {
      if (!Validator.isEmpty(value.mail)) {
        let owner1= this.appDetails.owner1;
          if (value.mail == owner1.mail) {
            return 'app owner email should be unique';
          }
        }
      }):Validator.value(value).required().regex('^[A-Za-z ]*$', 'Must only contain alphabetic characters.')
    },
    "appDetails.owner3": function (value) {
      return  value.mail? Validator.value(value.mail).required().email().custom(() =>
      {
      if (!Validator.isEmpty(value.mail)) {
        let owner1= this.appDetails.owner1;
        let owner2= this.appDetails.owner2;
          if (value.mail == owner1.mail || value.mail ==owner2.mail) {
          return 'app owner email should be unique';
          }
        }
      }):Validator.value(value).required().regex('^[A-Za-z ]*$', 'Must only contain alphabetic characters.')
    },
    "appDetails.relatedGroups": function (value) {
      return Validator.value(value).required();
    },
    "appDetails.description": (value) => {
      return Validator.value(value).required();
    },
    "appDetails.links": (value) => {
      return Validator.value(value).required();
    },
    "appDetails.environments": (value) => {
      return Validator.value(value).required();
    },
  },
};
</script>
<style scoped>
.floatlabel {
  border-radius: 2px;
  font-weight: 700;
  font-size: 13px !important;
}
.p-col-chip {
  background-color: #225083;
  color: white;
  font-weight: bold;
  display: inline !important;
}
</style>